import { render, staticRenderFns } from "./ShowMinimumAmount.vue?vue&type=template&id=02174fde&scoped=true"
import script from "./ShowMinimumAmount.vue?vue&type=script&lang=js"
export * from "./ShowMinimumAmount.vue?vue&type=script&lang=js"
import style0 from "./ShowMinimumAmount.vue?vue&type=style&index=0&id=02174fde&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02174fde",
  null
  
)

export default component.exports