import { render, staticRenderFns } from "./NoticeCreate.vue?vue&type=template&id=7959fc6d&scoped=true"
import script from "./NoticeCreate.vue?vue&type=script&lang=js"
export * from "./NoticeCreate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7959fc6d",
  null
  
)

export default component.exports