import { render, staticRenderFns } from "./LeaveLog.vue?vue&type=template&id=47fc8b4c&scoped=true"
import script from "./LeaveLog.vue?vue&type=script&lang=js"
export * from "./LeaveLog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47fc8b4c",
  null
  
)

export default component.exports