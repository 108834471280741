import { render, staticRenderFns } from "./CreatDiscount.vue?vue&type=template&id=4566536c&scoped=true"
import script from "./CreatDiscount.vue?vue&type=script&lang=js"
export * from "./CreatDiscount.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4566536c",
  null
  
)

export default component.exports